<script setup>
import { modalFlag } from '../store.js'
</script>

<template>
  <div class="modal-backdrop" vshow="modalFlag.isReadMoreOnCarbonVisible">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          How Much Does Rooftop Solar Reduce Your Carbon Footprint?
          <br>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/j2WnaIN9yg0?si=0vMU7K9JTqeV8zrV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <br>
          Perhaps the biggest environmental benefit of solar energy is its incredibly small carbon footprint. 
          <br>
          According to the International Panel on Climate Change (IPCC), the lifecycle emissions per kWh of electricity produced by rooftop solar are: 
          <ul>
            <li>
              Around 12 times less than electricity generated by natural gas (perhaps closer to 20 times less after factoring in methane leaks from natural gas)
            </li>
            <li>
              Around 20 times less than electricity generated by coal
            </li>

          </ul>

        </slot>
      </section>

      <footer class="modal-footer">
        <div>
          <button type="button" class="btn-green" @click="close">
            Ok
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadMoreOnCarbon',
  components: {
  },
  methods: {
    close() {
      this.$emit('close');
      modalFlag.isReadMoreOnCarbonVisible = false;
    },
    eversourceCustomer() {
       modalFlag.isReadMoreOnCarbonVisible = false;
    },
    notEversourceCustomer() {
       modalFlag.isReadMoreOnCarbonVisible = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
