<script setup>
import { modalFlag, recommendationConfig, recommendationReactive, duck } from '../store.js'
</script>

<template>
  <div>
    <h3 v-show="recommendationConfig.annualSavingsDuringLoan() >= 0">
      {{ recommendationReactive.customerName }} spends ${{ recommendationReactive.averageDailyEnergySpend.toFixed(2) }} per
      day for electricity from the grid, and could spend ${{ recommendationReactive.dailyPayment.toFixed(2) }} instead on
      solar panels. <br>
      ..and save ${{ ((recommendationReactive.averageDailyEnergySpend - recommendationReactive.dailyPayment) *
        365).toFixed(0) }} per year*.
      <br><br>
      CO<sup>2</sup> in the air is bad for the planet. Instead of adding {{ (recommendationReactive.fullYearUsagekWh * 820
        * .6 / 1000).toFixed(0) }} kg to the air yearly (weight of {{ ((recommendationReactive.fullYearUsagekWh * 820 * .6 /
    1000) / duck.weightKg).toFixed(0) }} ducks), {{ this.customerName() }} could add only {{
    (recommendationReactive.fullYearUsagekWh * 41 / 1000).toFixed(0) }} kg ({{ ((recommendationReactive.fullYearUsagekWh
    * 41 / 1000) / duck.weightKg).toFixed(0) }} ducks).

    </h3>
    <h3 v-show="recommendationConfig.annualSavingsDuringLoan() < 0">
      {{ recommendationConfig.customerName }} will pay at most
      ${{ -recommendationConfig.annualSavingsDuringLoan().toFixed(0) }} extra per year for electricity for {{
        recommendationConfig.consumerFinancingPreference.yearsOfLoan }} years*
    </h3>
    <h5>Reminder: Utility companies have raised electric prices on average 2.8% per year over past 20 years</h5>
  </div>
</template>

<script>
export default {
  name: 'FinanceMessage',
  props: {
    msg: String
  },
  components: {
  },
  methods: {
    showGetDataModal() {
      modalFlag.isEversourceCustomerQuestionVisible = true;
    },
    closeGetDataModal() {
      modalFlag.isEversourceCustomerQuestionVisible = false;
    },
    showReadMoreOnCarbon() {
      modalFlag.isReadMoreOnCarbonVisible = true;
    },
    closeReadMoreOnCarbon() {
      modalFlag.isReadMoreOnCarbonVisible = false;
    },
    customerName() {
      if (recommendationReactive.customerName.startsWith("Sample:")) {
        return recommendationReactive.customerName.substring(8)
      } else {
        return recommendationReactive.customerName
      }
    }
  },
  computed: {
    wasPanelInvestmentDataModalClosed() {
      return !modalFlag.isPanelInvestmentDataVisible
    },
  }
}
</script>

<style>
</style>
