<script setup>
import { chartData, modalFlag, chartKeys, recommendationReactive } from '../store.js';
</script>

<template>
  <div>
    <apexchart :key="chartKey" ref="realTimeChart" type="line" width="800" :options="chartOptions" :series="chartData.financeChartSeries">
    </apexchart>

  </div>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

export default {
  name: 'FinanceChart',
  props: {
    chartKey: Number,
  },
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    openPanelInvestmentModal() {
      modalFlag.isPanelInvestmentDataVisible = true;
    },
  },
  beforeMount() {
    chartKeys.finance += 1;
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          height: 350,
          width: 700,
          type: 'line',
          stroke: {
            width: 5
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true |'<img src="../assets/refresh-image.jpg" width="20">', // custom image not working
              customIcons: []
            },
          },
        },
        stroke: {
          show: true,
          colors: undefined,
        },
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        yaxis: [{
          title: {
            text: 'Your historical cost - $ per day (electricity)',
            style: {
              fontSize: "20px",
            }
          },
        }],
        annotations: {
          yaxis: [
          {
            y: recommendationReactive.dailyPayment,
            label: {
              borderColor: '#00E396',
              style: {
                color: '#333',
                background: '#CCFFBB',
              },
              text: 'Daily cost of solar panel payments',
            }
          }, 
          // {
          //   // y, y2 and text are not changing when their values change 
          //   y: recommendationReactive.dailyPayment,
          //   // y2: recommendationReactive.ChartMaxDailyEnergySpend, 
          //   y2: 9, // forced to 9 so top of range shows
          //   borderColor: '#000',
          //   fillColor: '#FEB019',
          //   opacity: 0.2,
          //   label: {
          //     borderColor: '#333',
          //     style: {
          //       fontSize: '10px',
          //       color: '#333',
          //       background: '#FEB019',
          //     },
          //     text: 'What '+ recommendationReactive.customerDisplayName + ' is spending now',
          //   }
          // }
        ],
        },
      },
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
