<script setup>
import { modalFlag, preferenceReactive, chartKeys } from '../store.js'
</script>

<template>
  <div class="center">


  <div class="grid-container">
    <div class="grid-item">      
        <FinanceChart 
          :chartKey="chartKeys.finance"
          v-show='preferenceReactive.purchaseOption == "finance"' 
          />
          
        <CashChart 
          :chartKey="chartKeys.cash" v-show='preferenceReactive.purchaseOption == "cash"' 
        />
        <p>See how much you spend, and how much you can save going solar </p>
    </div> 
    <div class="grid-item">
      <div> <img src="../assets/126472.png" width="30" @click="openPanelInvestmentModal" alt="see and change financial assumptions"> </div>

      <a href="https://drive.google.com/file/d/1-hPNuPhgEVoBhiLUEaikLbhktVMXkTd_/view?usp=sharing" download>
        <img src="../assets/spreadsheet_icon_773827.png" width="35"  alt="sample input template">
      </a>
    </div>

    <div class="grid-item2">}</div>
    <div class="grid-item3" v-show='preferenceReactive.purchaseOption == "finance"'>
      <h3>Your daily savings are what is above the horizontal line</h3>
    </div>
    <div class="grid-item3" v-show='preferenceReactive.purchaseOption == "cash"'>
      <h3>Your savings are the difference between the 2 lines</h3>
    </div>
  </div>
  <br>

    <div class="wrapper">
      <PanelInvestmentData v-if="modalFlag.isPanelInvestmentDataVisible"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import PanelInvestmentData from './PanelInvestmentData.vue';
import FinanceChart from './FinanceChart.vue'
import CashChart from './CashChart.vue'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

export default {
  name: 'TheChart',
  props: {
    msg: String
  }, 
  components: {
    PanelInvestmentData,
    FinanceChart,
    CashChart,
  },
  methods: {
    openPanelInvestmentModal()
    {
      modalFlag.isPanelInvestmentDataVisible = true;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .center {
    display: flex;
    justify-content: center;
  }

.wrapper {
  position:relative
}
button {
   position:absolute;
   top:0;
   left:0;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  padding: 10px;
}
.grid-item {
  /* to see grids, uncomment below line */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 5px;
}
.grid-item2 {
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 70px 5px;
  font-size: 260px;
  text-align: center;
}
.grid-item3 {
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 70px 5px;
  text-align: center;
  max-width: 120px;
}
</style>
