<script setup>
import { modalFlag, chartData, assumptionColumns, preferenceConfig, preferenceReactive } from '../store.js'
</script>

<template>
  <div class="modal-backdrop" vshow="modalFlag.isPanelInvestmentDataVisible">
    <div class="modal" size="xl">

      <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <InvestmentSummaryParagraph/> 
      <br>

      <AssumptionTable/>

      <div>
        <input type="checkbox" id="assumptionColumns checkbox" v-model="assumptionColumns.customizeColumn" />
        <label for="checkbox"> Customize </label>
        <input type="checkbox" id="assumptionColumns checkbox" v-model="assumptionColumns.jeffColumn" />
        <label for="checkbox"> Show Jeff Data </label>
        <br>

        <div>
          <input type="radio" id="cash" value="cash" v-model="preferenceReactive.purchaseOption">
          <label for="cash">Cash</label>
          <input type="radio" id="finance" value="finance" v-model="preferenceReactive.purchaseOption">
          <label for="finance">Finance</label>
       </div> 
      </div>

      <div><sup>*</sup> Get quotes from installers to verify pricing</div>
      <br>
      <div v-show='preferenceReactive.purchaseOption == "finance"'>Depending on your credit score, your interest rate on a loan should be between 8-20%</div>
      <div v-show='preferenceReactive.purchaseOption == "finance"'><sup>**</sup> Savings will increase once loan is paid</div>

      <footer class="modal-footer">
        <div>
          <button type="button" class="btn-green" @click="close">
            Ok
          </button>
        </div>
      </footer>

    </div>
  </div>
</template>

<script>
import AssumptionTable from './AssumptionTable.vue';
import InvestmentSummaryParagraph from './InvestmentSummaryParagraph.vue';

export default {
  name: 'PanelInvestmentData',
  components: {
    AssumptionTable,
    InvestmentSummaryParagraph,
  },
  methods: {
    close() {
      this.$emit('close');
      modalFlag.isPanelInvestmentDataVisible = false;
      if (preferenceReactive.yearsOfLoan  <= 0) {
        preferenceReactive.yearsOfLoan  = 1; // 0-year or negative-year loan isn't kosher, then pay cash, 0 year loan causes error in daily loan payment
      }
    },
    changeValue(newValue) {
      this.selectedValue = newValue;
      preferenceConfig.purchaseMethod = newValue;
    }
  },
  data: function () {
    return {
      mostRecentFullYearUsage: chartData.annualUsageHash[chartData.lastFullYear],
      // selectedValue: "finance"
    }
  },
  computed: {
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal {
  padding-left: 55px;
  padding-right: 55px;
/* make modal scrollable */
  overflow-y: auto;
  max-height: 900px;
}

h3.round {
  border: 2px solid;
  border-radius: 8px;
  padding: 5px;
}

</style>
