<script setup>
import { modalFlag } from '../store.js'
</script>

<template>
  <div class="modal-backdrop" v-if="modalFlag.isEversourceCustomerNotModalVisible">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          Currently this service is exclusively available for Eversource customers
        </slot>
      </section>

      <footer class="modal-footer">
        <button type="button" class="btn-green" @click="close">
          Ok
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EversourceCustomerNotModal',
  methods: {
    close() {
      modalFlag.isEversourceCustomerNotModalVisible = false;
      modalFlag.isEversourceCustomerQuestionVisible = false;
      this.$emit('close');     
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
