<script setup>
import { modalFlag } from '../store.js'
</script>

<template>
  <div class="modal-backdrop" vshow="modalFlag.isEversourceCustomerQuestionVisible">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          Do you get your electricity from Eversource?
        </slot>
      </section>

      <footer class="modal-footer">
        <div>
          <button type="button" class="btn-green" @click="eversourceCustomer">
            Yes
          </button>
          <button type="button" class="btn-green" @click="notEversourceCustomer">
            No
          </button>
        </div>
      </footer>
    </div>
    <EversourceOnlineQuestion v-if="modalFlag.isEversourceOnlineQuestionVisible"/>
    <EversourceCustomerNot v-if="modalFlag.isEversourceCustomerNotModalVisible"/>
  </div>
  
</template>

<script>
import EversourceCustomerNot from './EversourceCustomerNot.vue';
import EversourceOnlineQuestion from './EversourceOnlineQuestion.vue';

export default {
  name: 'EversourceCustomerQuestionModal',
  components: {
    EversourceCustomerNot,
    EversourceOnlineQuestion
  },
  methods: {
    close() {
      this.$emit('close');
      modalFlag.isEversourceCustomerNotModalVisible = false;
      modalFlag.isEversourceOnlineQuestionVisible = false;
    },
    eversourceCustomer() {
      modalFlag.isEversourceOnlineQuestionVisible = true;
    },
    notEversourceCustomer() {  
      modalFlag.isEversourceCustomerNotModalVisible = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
