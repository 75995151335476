<script setup>
import { modalFlag } from '../store.js'
</script>

<template>
  <div class="modal-backdrop" vshow="modalFlag.isHitHouseVisible">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          Hit the house icon to adjust scale & legend
          <br>
          <img src="../assets/house.png" width="250" /> 
        </slot>
      </section>

      <footer class="modal-footer">
        <div>
          <button type="button" class="btn-green" @click="close">
            Ok
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HitHouseToRefreshGraphScale',
  components: {
  },
  methods: {
    close() {
      this.$emit('close');
      modalFlag.isHitHouseVisible = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
