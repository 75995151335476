<script setup>
import { recommendationConfig, assumptionColumns, preferenceReactive } from '../store.js'
</script>

<template>
  <div class="center">
    <h3 class="round">
      <h2>{{ recommendationConfig.customerName }}</h2>
      Recommendation: <br>
      Based on your 
      {{ recommendationConfig.fullYearCostAndUsagePeriod }}
      usage of 
      {{ recommendationConfig.fullYearUsagekWh }} 
      kWh, a set of ({{ recommendationConfig.numPanelsRequired() }}) 
        {{ recommendationConfig.panel.wattsPerPanel }}-watt panels that provide 
      <div class="tooltip"> {{ recommendationConfig.kwhProducedAnnually().toFixed(0) }} kWh
        <span class="tooltiptext">{{ recommendationConfig.kwhProducedAnnually() }} kWh = 
          numPanels ({{ recommendationConfig.numPanelsRequired() }}) * wattsPerPanel ({{ recommendationConfig.panel.wattsPerPanel }}) * kWh produced annually per Kw ({{ recommendationConfig.kwhProducedAnnuallyPerKw() }})
        </span>
      </div>  
      will meet {{ (recommendationConfig.percentOfNeedMet()*100).toFixed(0) }}% of your need. <br>
  
    Estimated costs for ({{ recommendationConfig.numPanelsRequired() }}) {{ recommendationConfig.panel.wattsPerPanel }}-watt panels in {{ recommendationConfig.customerCityStateZip }}: $
    <div class="tooltip"> 
      {{ recommendationConfig.calculatedPanelsAndInstallationCost().toFixed(0) }} 
      <span class="tooltiptext">
        ${{ recommendationConfig.calculatedPanelsAndInstallationCost().toFixed(0) }} = 
      estimated panel cost (${{ recommendationConfig.panelsCost().toFixed(0) }}) 
      + estimated installation cost (${{ recommendationConfig.calculatedInstallationCost() }})
      </span> 
      including installation<sup>*</sup>
    </div> 
    <div>
      <div v-show='preferenceReactive.purchaseOption == "finance"'>
        This would provide an annual savings of ${{  recommendationConfig.annualSavingsDuringLoan().toFixed(0) }}.
      </div>
      <div v-show='preferenceReactive.purchaseOption == "cash"'>
        This would provide a 5-year Return on Investment of ${{  recommendationConfig.roiFiveYear().toFixed(0) }}%.
      </div>
      <br>
      Feel free to view and customize the assumptions with 
      <input 
      type="checkbox" 
      id="assumptionColumns checkbox" 
      v-model="assumptionColumns.customizeColumn" />
      Customize option
    </div>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'InvestmentSummaryParagraph',
  props: {
    config: Object,
  },
  components: {
  },
  methods: {
  },
  computed: {
  },
  data: function () {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .center {
    display: flex;
    justify-content: center;
  }
</style>
