<script setup>
import { chartData, assumptionColumns, recommendationConfig, preferenceConfig, jeffConfig, preferenceReactive } from '../store.js'
import {sunValues} from '../config.js'
import Panel from "../models/Panel";
import ConsumerFinancingPreference from "../models/ConsumerFinancingPreference";
import ConsumerProductionPreference from "../models/ConsumerProductionPreference";
</script>

<template>
  <div class="center round">
    <table id="firstTable">
        <thead>
          <tr>
            <th></th>
            <th>Recommended</th>
            <th v-show="assumptionColumns.customizeColumn">Customize</th>
            <th v-show="assumptionColumns.jeffColumn">Jeff's Installation</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>
                <div class="tooltip"> Sun on house
                  <span class="tooltiptext"> 
                    In Massachusetts, one kilowatt (kW) of an optimal solar PV system will generally produce {{sunValues.littleShade}} kWh of electricity per year, which means that a 5 kW system will produce roughly 6,000 kWh per year.<br><br>
                    Assumed kWh per year for each kilowatt of a panel<br>
                    Sunny: {{ sunValues.sunny }} <br>
                    A little shade: {{ sunValues.littleShade }} <br>
                    A lot of shade: {{ sunValues.lotOfShade }} <br>
                  </span>
                </div>

            </td>
            <td>{{ recommendationConfig.sun }}</td>
            <td v-show="assumptionColumns.customizeColumn">
              <select v-model=preferenceReactive.sun @change="updateObjectFromReactive()">
                <option disabled value="">Please select one</option>
                <option>Sunny</option>
                <option>A little shade</option>
                <option>A lot of shade</option>
              </select>
            </td>
            <td v-show="assumptionColumns.jeffColumn">{{ jeffConfig.sun }}</td>
          </tr>

          <tr>
            <td>% of your current electricity desired</td>
            <td>{{ (recommendationConfig.percentOfNeedMet()*100).toFixed(0) }}%</td>
            <td v-show="assumptionColumns.customizeColumn">
              <div>
                <input 
                v-model=preferenceReactive.percentOfNeedMet
                @change="updateObjectFromReactive()"
                type="text"
                placeholder="percent of need met" 
                 
                />
              </div>
            </td>
            <td v-show="assumptionColumns.jeffColumn">{{ jeffConfig.consumerProductionPreference.minimumPercentOfNeedMet }}%</td>
          </tr>

          <tr>
            <td>Panel Size</td>
            <td>
              {{ recommendationConfig.panel.wattsPerPanel  }} watts
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <select v-model=preferenceReactive.wattsPerPanel @change="updateObjectFromReactive()">
                <option disabled value="">Please select panel size</option>
                <option>490</option>
                <option>485</option>
                <option>480</option>
                <option>450</option>
                <option>440</option>
                <option>430</option>
                <option>410</option>
                <option>405</option>
                <option>400</option>
                <option>395</option>
                <option>390</option>
                <option>385</option>
                <option>380</option>
                <option>370</option>
                <option>365</option>
                <option>360</option>
                <option>355</option>
                <option>350</option>
                <option>345</option>
              </select>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              {{ jeffConfig.panel.wattsPerPanel }} watts
            </td>
          </tr>

          <tr>
            <td>Number of Panels Required</td>
            <td>
              {{ recommendationConfig.numPanelsRequired() }}
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              {{ preferenceConfig.numPanelsRequired() }} 
              <!-- {{ this.numPanelsRequired }}  -->
            </td>
            <td v-show="assumptionColumns.jeffColumn"> 
              {{ jeffConfig.numPanelsRequired() }} 
            </td>
          </tr>

          <tr>
            <td>

              <div class="tooltip"> Space required for panels
                <span class="tooltiptext"> 
                  Most roof-mounted solar installations will need a “solar panel setback” for safety. This is one of the most common roof requirements for solar panels in local and state building codes. This setback is the open space between the edge of the solar array and the edge of the roof, and it provides an unobstructed pathway around your rooftop for emergency responders like firefighters to get better access to your home in case of an emergency.
                  <br>
                  The minimum solar panel setback varies from state to state, but generally, the setback will take up about 25 percent of your roof’s usable space. This accounts for two roughly 36-inch wide pathways that run along the edge of your roof, on a roof with just two basic faces. 
                  <br>
                  If your roof is more complicated than that, with multiple faces, or different shapes that come together at odd angles, your setback requirements may be different.
                </span>
              </div>
            </td>
          
            <td>
              {{ recommendationConfig.spaceRequired().toFixed(0) }} sq ft 
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              {{ preferenceConfig.spaceRequired().toFixed(0) }} sq ft 
            </td>
            <td v-show="assumptionColumns.jeffColumn"> 
              {{ jeffConfig.spaceRequired().toFixed(0) }} sq ft 
            </td>
          </tr>
          
          <tr>
            <td>System Size</td>
            <td>
              {{ recommendationConfig.systemSizekW().toFixed(1) }} kW
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              {{ preferenceConfig.systemSizekW().toFixed(1) }} kW
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              {{ jeffConfig.systemSizekW().toFixed(1) }} kW
            </td>
          </tr>
          
          <tr>
            <td>Will generate per year</td>
            <td>
              {{ recommendationConfig.kwhProducedAnnually().toFixed(0) }} kWh
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              {{ preferenceConfig.kwhProducedAnnually().toFixed(0) }} kWh
            </td>
            <td v-show="assumptionColumns.jeffColumn">
               {{ (jeffConfig.kwhProducedAnnually()).toFixed(0) }} kWh
            </td>
          </tr>

          <tr>
            <td>Cost per Panel*</td>
            <td>
              <div class="tooltip">  ${{ recommendationConfig.costPerPanel().toFixed(2) }}
                <span class="tooltiptext"> 
                  On average, monocrystalline solar panels (the most energy-efficient solar panel option) cost $1 to $1.50 per watt - Forbes.com Sep 12, 2023 <br>
                  {{ recommendationConfig.panel.wattsPerPanel }} watts x $1.50 = ${{ recommendationConfig.calculatedCostPerPanel().toFixed(2) }}
                </span>
              </div>
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              ${{ preferenceConfig.costPerPanel().toFixed(2) }} 
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              ${{ jeffConfig.costPerPanel().toFixed(2) }}
            </td>
          </tr>

          <br>
          
          <tr>
            <td>Total Panel Cost</td>
            <td>
              ${{ recommendationConfig.panelsCost().toFixed(0) }}
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              ${{ preferenceConfig.panelsCost().toFixed(0) }} 
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              ${{ jeffConfig.panelsCost().toFixed(0) }}
            </td>
          </tr>

          <tr>
            <td>Installation*</td>
              <td>
                <div class="tooltip"> ${{ recommendationConfig.calculatedInstallationCost().toFixed(0) }}
                  <span class="tooltiptext"> 
                    On average, installation is 25% the cost of panels <br>
                    Forbes.com Sep 12, 2023
                  </span>
                </div>
              </td>
            <td v-show="assumptionColumns.customizeColumn">
              ${{ preferenceConfig.calculatedInstallationCost().toFixed(0) }}
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              ${{ jeffConfig.calculatedInstallationCost().toFixed(0) }}
            </td>
          </tr>

          <tr>
            <td>
              <div class="tooltip"> Federal Incentive*
                <span class="tooltiptext"> 
                  Disclaimer: This guide provides an overview of the federal investment tax credit for residential solar photovoltaics (PV). (See the Federal Solar Tax Credits for Businesses for information for businesses). It does not constitute professional tax advice or other professional financial guidance and may change based on additional guidance from the Treasury Department. This guide should not be used as the only source of information when making purchasing decisions, investment  decisions, tax decisions, or when executing other binding agreements. 
                </span>
              </div>
            </td>

            <td>
              <div class="tooltip"> ${{ recommendationConfig.federalIncentive().toFixed(0) }}
                <span class="tooltiptext"> 
                  Solar PV systems installed between 2022-2032 are eligible for a 30% federal tax credit on panels & installation. <br>
                  Panels and Installation 
                  $ {{ recommendationConfig.calculatedPanelsAndInstallationCost().toFixed() }}
                   x 30% = 
                  ${{ -recommendationConfig.federalIncentive().toFixed(0) }} <br>
                </span>
              </div>
            </td>



            <td v-show="assumptionColumns.customizeColumn">
              <div class="tooltip"> $ {{ preferenceConfig.federalIncentive().toFixed(0) }}
                <span class="tooltiptext"> 
                  Solar PV systems installed between 2022-2032 are eligible for a 30% federal tax credit on panels & installation. <br>
                  Panels and Installation 
                  ${{ preferenceConfig.calculatedPanelsAndInstallationCost().toFixed(0) }}
                   x 30% =
                    ${{ -preferenceConfig.federalIncentive().toFixed(0) }} <br>
                </span>
              </div>
            </td>

            <td v-show="assumptionColumns.jeffColumn">
              ${{ jeffConfig.federalIncentive().toFixed(0) }}
            </td>
          </tr>

          <tr>
            <td><b>Total Cost</b></td>
            <td><b>
              ${{ recommendationConfig.totalCost().toFixed(0) }}
            </b></td>

            <td v-show="assumptionColumns.customizeColumn"><b>
              ${{ preferenceConfig.totalCost().toFixed(0) }}
            </b></td>

            <td v-show="assumptionColumns.jeffColumn"><b>
              ${{ jeffConfig.totalCost().toFixed(0) }}
            </b></td>
          </tr>

          <br>
          <tr>
            <td>
              <div class="tooltip"> Additional State Incentives*
                <span class="tooltiptext"> 
                  Disclaimer: This guide provides an overview of state investment tax credits for residential solar photovoltaics (PV). It does not constitute professional tax advice or other professional financial guidance and may change based on additional guidance from the State. This guide should not be used as the only source of information when making purchasing decisions, investment  decisions, tax decisions, or when executing other binding agreements. 
                </span>
              </div>
            </td>

            <td>
              <div class="tooltip"> ${{ recommendationConfig.stateIncentives().toFixed(0) }}
                <span class="tooltiptext"> 
                  In MA, Solar PV systems are also eligible for: <br>
                  <!-- error here -->
                  <!-- a ${{ -recommendationConfig.MAIncomeTaxIncentive().toFixed(0) }}  -->
                  State Income Tax credit<br>
                  a MA Smart Credit
                </span>
              </div>
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <div class="tooltip"> $ {{ preferenceConfig.stateIncentives().toFixed(0) }}
                <span class="tooltiptext"> 
                  In MA, Solar PV systems are eligible for: <br>
                  <!-- a ${{ -preferenceConfig.MAIncomeTaxIncentive().toFixed(0) }}  -->
                  State Income Tax credit<br>
                  a MA Smart Credit
                </span>
              </div>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              ${{ jeffConfig.stateIncentives().toFixed(0) }}
            </td>
          </tr>

          <br>
          
          <!-- financing options -->
          <tr v-show='preferenceReactive.purchaseOption == "finance"'>
            <td>Years of Loan</td>
            <td>
              {{ recommendationConfig.consumerFinancingPreference.yearsOfLoan }}
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <div>
                <input 
                v-model=preferenceReactive.yearsOfLoan
                  type="text"
                  placeholder="years of loan" 
                  @change="updateObjectFromReactive()"
                  />
              </div>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              {{ jeffConfig.consumerFinancingPreference.yearsOfLoan }}
            </td>
          </tr>
  
          <tr v-show='preferenceReactive.purchaseOption == "finance"'>
            <td>Interest Rate</td>
            <td>
              {{ recommendationConfig.consumerFinancingPreference.interestRate }}%
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <div>
                <input 
                v-model=preferenceReactive.interestRate
                  type="text"
                  placeholder="interest rate" 
                  @change="updateObjectFromReactive()"
                  />
              </div>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              {{ jeffConfig.consumerFinancingPreference.interestRate }}%
            </td>
          </tr>

          <tr v-show='preferenceReactive.purchaseOption == "finance"'>
            <td>Monthly Cost</td>
            <td>
              ${{ recommendationConfig.monthlyPayment().toFixed(0) }}
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              ${{ preferenceReactive.monthlyPayment.toFixed(0) }} 
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              ${{jeffConfig.monthlyPayment().toFixed(0)}}
            </td>
          </tr>

          <tr v-show='preferenceReactive.purchaseOption == "finance"'>
            <td>Average Daily Savings**</td>
            <td>
              <b>${{ recommendationConfig.averageDailySavingsDuringLoan().toFixed(2) }}</b>
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <b>${{ preferenceConfig.averageDailySavingsDuringLoan().toFixed(2) }}</b>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              <b>
                ${{ jeffConfig.averageDailySavingsDuringLoan().toFixed(2) }}
              </b> 
            </td>
          </tr>

          <tr v-show='preferenceReactive.purchaseOption == "finance"'>
            <td>Annual Savings**</td>
            <td>
              <b> ${{ recommendationConfig.annualSavingsDuringLoan().toFixed(0) }}</b>
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <b>${{ preferenceConfig.annualSavingsDuringLoan().toFixed(0) }}</b>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              <b>   
                <div class="tooltip"> ${{ jeffConfig.annualSavingsDuringLoan().toFixed(0) }} 
                  <span class="tooltiptext"> 
                    Savings will be at least ${{jeffConfig.fullYearCost.toFixed(0)}} per year when loan is paid
                  </span>
                </div>    
              </b> 
            </td>
          </tr>

          <!-- purchase options -->
          <tr v-show='preferenceReactive.purchaseOption == "cash"'>
            <td>Annual Savings</td>
            <td>
              <b> ${{ recommendationConfig.fullYearCost.toFixed(0) }}</b>
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <b> ${{preferenceReactive.fullYearCost.toFixed(0)}}</b>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              <b> ${{ jeffConfig.fullYearCost.toFixed(0) }}</b> 
            </td>
          </tr>

          <tr v-show='preferenceReactive.purchaseOption == "cash"'>
            <td>Payback Period</td>
            <td>
              <b> {{ recommendationConfig.paybackPeriod().toFixed(0) }} years</b>
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <b>{{ preferenceReactive.paybackPeriod.toFixed(0) }} years</b>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              <b> {{ jeffConfig.paybackPeriod().toFixed(0) }} years </b> 
            </td>
          </tr>

          <tr v-show='preferenceReactive.purchaseOption == "cash"'>
            <td>5-year Return on Investment</td>
            <td>
              <b>{{ recommendationConfig.roiFiveYear().toFixed(0) }}%</b>
            </td>
            <td v-show="assumptionColumns.customizeColumn">
              <b> {{preferenceReactive.roiFiveYear.toFixed(0)}}%</b>
            </td>
            <td v-show="assumptionColumns.jeffColumn">
              <b>{{ jeffConfig.roiFiveYear().toFixed(0) }}%</b> 
            </td>
          </tr>


        </tbody>
      </table>
  
    </div>
</template>

<script>
export default {
  name: 'AssumptionTable',
  props: {
    configRecommendation: Object,
    configJeff: Object
  }, 
  components: {
  },
  methods: {
    updateObjectFromReactive() {
      // update recommendation object from reactive variables
      preferenceConfig.sun = preferenceReactive.sun;

      preferenceConfig.panel = new Panel(preferenceReactive.wattsPerPanel);

      preferenceConfig.consumerFinancingPreference = new ConsumerFinancingPreference(preferenceReactive.yearsOfLoan, preferenceReactive.interestRate);
      
      preferenceConfig.consumerProductionPreference = new ConsumerProductionPreference(preferenceReactive.percentOfNeedMet);

      // update reactive variables from object when inputs are updated by user
      preferenceReactive.monthlyPayment = preferenceConfig.monthlyPayment();
      preferenceReactive.numPanels = preferenceConfig.numPanelsRequired();
      preferenceReactive.fullYearCost = preferenceConfig.fullYearCost;
      preferenceReactive.paybackPeriod = preferenceConfig.paybackPeriod();
      preferenceReactive.roiFiveYear = preferenceConfig.roiFiveYear();
    }
  },
  computed: {
    mostRecentFullYearUsage() {
      return chartData.lastFullYear ? chartData.annualUsageHash[chartData.lastFullYear] : chartData.oneYearUsage
    },
    annualCost() {
      // calculate annual cost if there is not a full calendar year of data
      return chartData.lastFullYear ? chartData.annualCostHash[chartData.lastFullYear] : chartData.oneYearCost
    },

  },
  data: function () {
    return {
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .center {
    display: flex;
    justify-content: center;
  }

div.round {
  border: 2px solid;
  border-radius: 8px;
  padding: 5px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  top: -30px;
  right: -300%;
}
</style>
