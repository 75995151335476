import { reactive } from "vue";
import SolarConfig from "./models/SolarConfig";
import Panel from "./models/Panel";
import ConsumerFinancingPreference from "./models/ConsumerFinancingPreference";
import ConsumerProductionPreference from "./models/ConsumerProductionPreference";

// ***** sample configs

// panels
const panel395Watts = new Panel(395);
const jeffPanel = new Panel(395, 1589.2); // include actual cost as 2nd parameter, if available

// financing
const recommendationFinancingPreference = new ConsumerFinancingPreference(
  25,
  8
);
const jeffFinancingPreference = new ConsumerFinancingPreference(25, 1.99);

// production
const recommendationProductionPreference = new ConsumerProductionPreference(
  103
);
const jeffProductionPreference = new ConsumerProductionPreference(151);

export const recommendationConfig = new SolarConfig(
  "Sunny",
  panel395Watts,
  9802,
  2950.12,
  recommendationProductionPreference,
  recommendationFinancingPreference,
  "2022",
  "Sample: Jeff",
  "Waltham, MA"
);

export const preferenceConfig = new SolarConfig(
  "Sunny",
  panel395Watts,
  9802,
  2950.12,
  recommendationProductionPreference,
  recommendationFinancingPreference,
  "2022",
  "Sample: Jeff",
  "Waltham, MA"
);

export const jeffConfig = new SolarConfig(
  "Sunny",
  jeffPanel,
  9802,
  2950.12,
  jeffProductionPreference,
  jeffFinancingPreference,
  "2022",
  "Jeff",
  "Waltham, MA"
);

// *********

// create state objects
export const modalFlag = reactive({
  isEversourceCustomerQuestionVisible: false,
  isEversourceCustomerNotModalVisible: false,
  isEversourceOnlineQuestionVisible: false,
  isEversourceOnlineNotModalVisible: false,
  isEversourceHowToGetDataVisible: false,
  isPanelInvestmentDataVisible: false,
  isReadMoreOnCarbonVisible: false,
  isHitHouseVisible: false,
});

export const chartKeys = reactive({
  // need to manually refresh keys to refresh a chart
  cash: 0,
  finance: 100000, // arbitrarily high number
});

export const assumptionColumns = reactive({
  customizeColumn: false,
  jeffColumn: false,
});

export const panel = reactive({
  costPerDay: Array(12).fill(2.24), // sample daily cost (generated by model)
});

export const duck = reactive({
  weightKg: 2,
});

// fields that need to be reactive
export const recommendationReactive = reactive({
  // for EnerChart.vue
  customerName: "Sample: Bob",
  customerDisplayName: "Bob",
  totalCost: 8813, // panels + installation - federal incentive
  annualSavingsDuringLoan: 2134,
  dailyPayment: 2.24,
  averageDailyEnergySpend: 8.08,
  maxDailyEnergySpend: 16.13,
  fullYearUsagekWh: 9802,
  fullYearCost: 2950.12, // money spent currently on electricity
  percentOfNeedMet: 1.03,
  paybackPeriod: 3,
  roiFiveYear: 167,
});

export const preferenceReactive = reactive({
  // those that users can adjust
  sun: "Sunny",
  wattsPerPanel: 395,
  yearsOfLoan: 25,
  interestRate: 8,
  purchaseOption: "finance",
  // those that are calculated, so users can't adjust
  monthlyPayment: 68,
  numPanels: 25,
  fullYearCost: 2950,
  percentOfNeedMet: 100,
  paybackPeriod: 3,
  roiFiveYear: 167,
});

export const calculatedValues = reactive({
  annualSavingsRecommendation: 2133,
});

export const chartData = reactive({
  // initialized with sample data
  annualCostHashJeff: {
    2022: 2950.12,
  },
  annualUsageHashJeff: {
    2022: 9802,
  },
  lastFullYearJeff: 2022,
  customerCityStateZip: "Waltham, MA",
  averageDailyUsage: 26.3,
  years: [2020, 2021, 2022, 2023],
  annualCostHash: {
    2022: 2950.12,
  },
  annualUsageHash: {
    2022: 9802,
  },
  lastFullYear: 2022,

  cashChartSeries: [
    {
      name: "lifetime grid cost",
      data: [
        0,
        Math.round(recommendationReactive.fullYearCost * 3),
        Math.round(recommendationReactive.fullYearCost * 6),
        Math.round(recommendationReactive.fullYearCost * 9),
        Math.round(recommendationReactive.fullYearCost * 12),
      ],
    },
    {
      name: "lifetime panel cost",
      data: [
        Math.round(recommendationReactive.totalCost),
        Math.round(recommendationReactive.totalCost),
        Math.round(recommendationReactive.totalCost),
        Math.round(recommendationReactive.totalCost),
        Math.round(recommendationReactive.totalCost),
      ],
    },
  ],

  financeChartSeries: [
    {
      name: 2020,
      type: "column",
      data: [
        null,
        null,
        null,
        "7.09",
        "6.24",
        "6.37",
        "8.93",
        "10.83",
        "7.47",
        "5.66",
        "7.60",
        "7.12",
      ],
    },
    {
      name: 2021,
      type: "column",
      data: [
        "7.50",
        "7.72",
        "6.49",
        "5.81",
        "5.86",
        "6.58",
        "6.07",
        "9.39",
        "9.30",
        "6.91",
        "7.98",
        "7.99",
      ],
    },
    {
      name: 2022,
      type: "column",
      data: [
        "7.73",
        "8.23",
        "3.25",
        "7.99",
        "7.30",
        "10.20",
        "10.98",
        "16.13",
        "8.75",
        "7.52",
        "3.64",
        "5.33",
      ],
    },
    {
      name: 2023,
      type: "column",
      data: [
        "10.18",
        "9.24",
        "9.73",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        null,
        null,
        null,
        null,
      ],
    },
    {
      name: "Your estimated solar spend",
      type: "line",
      data: panel.costPerDay,
    },
  ],

  //  used if no full calendar year of data
  oneYearUsage: null,
  oneYearUsagePeriod: "",
  oneYearCost: null,
  oneYearCostPeriod: "",

  usageMonthlyHash: {
    2020: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      "27.8",
      "20.9",
      "28.2",
      "26.5",
    ],
    2021: [
      "27.6",
      "28.4",
      "23.8",
      "20.9",
      "20.6",
      "23.3",
      "21.4",
      "33.7",
      "33.5",
      "24.6",
      "28.6",
      "28.5",
    ],
    2022: [
      "27.2",
      "25.3",
      "22.1",
      "21.8",
      "19.8",
      "28.0",
      "30.0",
      "43.9",
      "23.5",
      "20.1",
      "23.7",
      "35.7",
    ],
    2023: [
      "27.2",
      "24.3",
      "22.6",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      null,
      null,
      null,
      null,
    ],
  },
  costDailyHash: {
    2020: [
      null,
      null,
      null,
      "7.09",
      "6.24",
      "6.37",
      "8.93",
      "10.83",
      "7.47",
      "5.66",
      "7.60",
      "7.12",
    ],
    2021: [
      "7.50",
      "7.72",
      "6.49",
      "5.81",
      "5.86",
      "6.58",
      "6.07",
      "9.39",
      "9.30",
      "6.91",
      "7.98",
      "7.99",
    ],
    2022: [
      "7.73",
      "8.23",
      "3.25",
      "7.99",
      "7.30",
      "10.20",
      "10.98",
      "16.13",
      "8.75",
      "7.52",
      "3.64",
      "5.33",
    ],
    2023: [
      "10.18",
      "9.24",
      "9.73",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  },
  costMonthlyHash: {
    2020: [
      null,
      null,
      null,
      "198.47",
      "205.93",
      "184.78",
      "294.80",
      "325.02",
      "216.72",
      "186.75",
      "220.37",
      "227.90",
    ],
    2021: [
      "224.88",
      "223.85",
      "188.31",
      "180.05",
      "181.59",
      "210.54",
      "176.11",
      "272.24",
      "306.98",
      "207.18",
      "263.30",
      "223.71",
    ],
    2022: [
      "231.78",
      "238.78",
      "103.91",
      "231.58",
      "218.87",
      "295.78",
      "362.36",
      "483.90",
      "279.89",
      "225.53",
      "101.93",
      "175.81",
    ],
    2023: [
      "305.27",
      "267.99",
      "311.27",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  },
});
