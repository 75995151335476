<script setup>
import { modalFlag, chartData, chartKeys } from '../store.js';
</script>

<template>
  <apexchart :key="chartKey" ref="realTimeChart" type="line" width="800" :options="chartOptions" :series="chartData.cashChartSeries">
  </apexchart>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

export default {
  name: 'CashChart',
  props: {
    chartKey: Number,
  },
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    openPanelInvestmentModal() {
      modalFlag.isPanelInvestmentDataVisible = true;
    },
  },
  beforeMount(){
    chartKeys.cash += 1;
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          height: 350,
          width: 700,
          type: 'line',
          stroke: {
            width: 5
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
          },
        },
        stroke: {
          show: true,
          colors: undefined,
        },
        // labels: [],
        xaxis: {
          categories: [new Date().getFullYear(), new Date().getFullYear()+3, new Date().getFullYear()+6, new Date().getFullYear()+9, new Date().getFullYear()+12],
        },
        yaxis: [{
          title: {
            text: 'your lifetime cost',
            style: {
              fontSize: "20px",
            }
          },
        }],
        // annotations: {
        //   points: [{
        //     x: 2026,  // to be calculated
        //     y: 8850,  // to be calculated
        //     marker: {
        //       size: 8,
        //       radius: 2,
        //     },
        //     label: {
        //       borderColor: '#00E396',
        //       offsetY: 0,
        //       style: {
        //         color: '#fff',
        //         background: '#00E396',
        //       },

        //       text: 'Break-even point',
        //     }
        //   }
        //   ]
        // },
      },
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
