<script setup>
import { modalFlag, recommendationReactive, duck } from '../store.js'
</script>

<template>
  <div>
    <h3>
      {{ recommendationReactive.customerName }} spends ${{ (recommendationReactive.fullYearCost).toFixed(0) }}
      per year for electricity from the grid. <br/>
      Instead for an investment of ${{ recommendationReactive.totalCost.toFixed(0) }} could have {{ (recommendationReactive.percentOfNeedMet*100).toFixed(0) }}% of current electricity. <br/>

      The payback period of the investment is {{ recommendationReactive.paybackPeriod.toFixed(0) }} years and the 5-year return on investment is {{ recommendationReactive.roiFiveYear.toFixed(0) }}%.
      <br><br>
      CO<sup>2</sup> in the air is bad for the planet. Instead of adding {{ (recommendationReactive.fullYearUsagekWh * 820
        * .6 / 1000).toFixed(0) }} kg to the air yearly (weight of {{ ((recommendationReactive.fullYearUsagekWh * 820 * .6 /
    1000) / duck.weightKg).toFixed(0) }} ducks), {{ this.customerName() }} could add only {{
    (recommendationReactive.fullYearUsagekWh * 41 / 1000).toFixed(0) }} kg ({{ ((recommendationReactive.fullYearUsagekWh
    * 41 / 1000) / duck.weightKg).toFixed(0) }} ducks).
    </h3>

    <h5>Reminder: Utility companies have raised electric prices on average 2.8% per year over past 20 years</h5>
  </div>
</template>

<script>
export default {
  name: 'CashMessage',
  props: {
    msg: String
  },
  components: {
  },
  methods: {
    showGetDataModal() {
      modalFlag.isEversourceCustomerQuestionVisible = true;
    },
    closeGetDataModal() {
      modalFlag.isEversourceCustomerQuestionVisible = false;
    },
    showReadMoreOnCarbon() {
      modalFlag.isReadMoreOnCarbonVisible = true;
    },
    closeReadMoreOnCarbon() {
      modalFlag.isReadMoreOnCarbonVisible = false;
    },
    customerName() {
      if (recommendationReactive.customerName.startsWith("Sample:")) {
        return recommendationReactive.customerName.substring(8)
      } else {
        return recommendationReactive.customerName
      }
    }
  },
  computed: {
    wasPanelInvestmentDataModalClosed() {
      return !modalFlag.isPanelInvestmentDataVisible
    },
  }
}
</script>

<style></style>
