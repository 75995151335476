<script setup>
import { modalFlag } from '../store.js'
</script>

<template>
  <div class="modal-backdrop" v-show="modalFlag.isEversourceOnlineQuestionVisible">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          Have you signed up for an Eversource online account?
        </slot>
      </section>

      <footer class="modal-footer">
        <div>
          <button type="button" class="btn-green" @click="eversourceOnline">
            Yes
          </button>
          <button type="button" class="btn-green" @click="notEversourceOnline">
            No
          </button>
        </div>
      </footer>
    </div>
    <EversourceOnlineNot v-if="modalFlag.isEversourceOnlineNotModalVisible"/>
    <EversourceHowToGetData v-if="modalFlag.isEversourceHowToGetDataVisible"/>
  </div>
</template>

<script>
import EversourceOnlineNot from './EversourceOnlineNot.vue'
import EversourceHowToGetData from './EversourceHowToGetData.vue';

export default {
  name: 'EversourceOnlineQuestionModal',
  components: {
    EversourceOnlineNot,
    EversourceHowToGetData
  },
  methods: {
    close() {
      this.$emit('close');
      modalFlag.isEversourceOnlineQuestionVisible = false;
    },
    eversourceOnline() {
      modalFlag.isEversourceHowToGetDataVisible = true;
    },
    notEversourceOnline() {
      modalFlag.isEversourceOnlineNotModalVisible = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
