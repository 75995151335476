<script setup>
import { modalFlag } from '../store.js'
</script>

<template>
  
  <div class="modal-backdrop" v-if="modalFlag.isEversourceHowToGetDataVisible">
    <div class="modal">
    <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <section class="modal-body" size="lg">
        <ol>
          <slot name="body">
            <h3>Here's how you get your data:</h3>
            <iframe width="420" height="315"
              src="https://www.youtube.com/embed/7uuBIInbUN8">
            </iframe>
            <li>
              Go to <a href="https://www.eversource.com" target="_blank" rel="noopener">Eversource.com</a>
                - you'll be redirected to <a href="https://www.eversource.com/content/residential " target="_blank" rel="noopener">https://www.eversource.com/content/residential </a>
              </li>
              <li>Sign in - If you don't have an account you will have to 
                <a href="https://www.eversource.com/security/account/Register" target="_blank" rel="noopener">sign up</a> first.  This is free for all customers.
              </li>
              <li> Under Usage Details - click "see more details" </li>
              <img src="../assets/see-more-details.png" width="250" /> 
              <li> Choose how much data you want (recommendation: 36 months)</li>
                <img src="../assets/view-data-period.png" width="300" />               
              <li> Hit "Download my secure data with Green Button"</li>
                <img src="../assets/download-my-secure-data.png" width="300" />   
              <li>When asked, choose CSV format  (Remember where you store the file)</li>
              <img src="../assets/data-format.png" width="300" /> 
              <br>
          </slot> 
        </ol>
      </section>

      <footer class="modal-footer">
        <button type="button" class="btn-green" @click="close">
          Ok
        </button>
      </footer>

    </div>
  </div>
</template>

<script>
export default {
  name: 'EversourceHowToGetData',
  methods: {
    close() {
      modalFlag.isEversourceHowToGetDataVisible = false;
      modalFlag.isEversourceOnlineQuestionVisible = false;
      modalFlag.isEversourceCustomerQuestionVisible = false;
      this.$emit('close');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-body {
  padding-top: 5px;
  padding-left: 55px;
  padding-right: 55px;
}

li {
  text-align:left;
}

/* make modal scrollable */
.modal {
  overflow-y: auto;
  max-height: 900px;
}
</style>
