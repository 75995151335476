<script setup>
import { chartData, recommendationConfig, preferenceConfig, recommendationReactive, panel, chartKeys } from '../store.js'
</script>

<template>
  <div>
    <input 
      type="file" 
      ref="doc" 
      @change="readFile()" 
      class="btn-green"
    />
  </div>
</template>

<script>
export default {
  name: 'ReadCSVButtonEversource',
  methods: {
    convertToMonthYear(yearMonth) {
      const year = yearMonth.substring(0,4)
      const month = yearMonth.substring(5)
      return month + "/" + year
    },
    readFile() {
      this.file = this.$refs.doc.files[0];
      const reader = new FileReader();
      this.content = "check the console for file output";
      reader.onload = (res) => {
        // clears old hash values out of memory
        Object.keys(chartData.costDailyHash).forEach((key) => {
          chartData.costDailyHash[key] = Array(12).fill(null);
        })
        Object.keys(chartData.costMonthlyHash).forEach((key) => {
          chartData.costMonthlyHash[key] = Array(12).fill(null);
        })
        Object.keys(chartData.usageMonthlyHash).forEach((key) => {
          chartData.usageMonthlyHash[key] = Array(12).fill(null);
        })

        const textLines = res.target.result.split("\n");

        // process csv header
        const nameElements = textLines[0].split(',')  // split() for use case when I create my own CSVs
        const name = nameElements[0]
        const customerCityStateZipElements = textLines[2].split(',')
        recommendationConfig.customerName = name;
        recommendationConfig.customerCityStateZip = customerCityStateZipElements[0]
        // may be useful in the future
        // const address = textLines[1]
        // const account = textLines[9]
        recommendationReactive.customerName = name;
        recommendationReactive.customerDisplayName = name;

        for (let i = 9; i < textLines.length; i++) {
          if (textLines[i] != ''){
            let lineElements = textLines[i].split(",")
            let readDate = lineElements[1];
            let usage = lineElements[2];
            let numDays = lineElements[3];
            let charge = lineElements[5].replace('$', '');
  
            let readDateElements = readDate.split("/");
            let month = readDateElements[0];
            let year = readDateElements[2];
            let chargePerDay = parseFloat(charge/numDays).toFixed(2);
            let chargePerMonth = parseInt(charge).toFixed(2);
            
            // cost hash
            let thisYearDailyCost
            let thisYearMonthlyCost
            if (!chartData.costDailyHash[year]) {  
              thisYearDailyCost = Array(12).fill(null);
              thisYearMonthlyCost = Array(12).fill(null);
            } else {
              thisYearDailyCost = chartData.costDailyHash[year];
              thisYearMonthlyCost = chartData.costMonthlyHash[year];
            }
            thisYearDailyCost[month-1] = chargePerDay;
            thisYearMonthlyCost[month-1] = chargePerMonth;
            chartData.costDailyHash[year] = thisYearDailyCost;
            chartData.costMonthlyHash[year] = thisYearMonthlyCost;
  
            // usage hash
            let thisYearMonthlyUsage
            if (!chartData.usageMonthlyHash[year]) {  
              thisYearMonthlyUsage = Array(12).fill(null);
            } else {
              thisYearMonthlyUsage = chartData.usageMonthlyHash[year];
            }
            thisYearMonthlyUsage[month-1] = usage
            chartData.usageMonthlyHash[year] = thisYearMonthlyUsage;
          }
          
        }

        // does not handle use case of less than 12 months data

        // clears old hash values out of memory
        let usageMonthHash = {}
        let costMonthHash = {}
        Object.keys(usageMonthHash).forEach((key) => {
          usageMonthHash[key] = null;
          costMonthHash[key] = null;
        })
        chartData.years.forEach((year) => {

          chartData.usageMonthlyHash[year].forEach((monthUsage, monthNum) => {
            if (monthUsage) {
              let monthYear =  year + "/" + (monthNum + 1).toString().padStart(2, '0');
              usageMonthHash[monthYear] = parseInt(monthUsage)
            }
          })

          chartData.costMonthlyHash[year].forEach((monthCost, monthNum) => {
            if (monthCost) {
              let monthYear =  year + "/" + (monthNum + 1).toString().padStart(2, '0');
              costMonthHash[monthYear] = parseInt(monthCost)
            }
          })
          
        })
        let yearMonths =  Object.keys(usageMonthHash)
        let monthUsages = Object.values(usageMonthHash)
        let monthCosts = Object.values(costMonthHash)
        const startIndex = Math.max(monthUsages.length-12, 0)
        let firstYearMonth = yearMonths[startIndex]
        let lastYearMonth = yearMonths[yearMonths.length-1]
        const last12MonthsUsages =  monthUsages.slice(startIndex)
        const last12MonthsCosts = monthCosts.slice(startIndex)
        let totalYearUsage = 0
        let totalYearCost = 0
        last12MonthsUsages.forEach((usage) => {
          totalYearUsage += usage
        })
        last12MonthsCosts.forEach((cost) =>{
          totalYearCost += cost
        })
        recommendationConfig.fullYearUsagekWh = totalYearUsage
        recommendationConfig.fullYearCost = totalYearCost
        recommendationConfig.fullYearCostAndUsagePeriod = this.convertToMonthYear(firstYearMonth) + " to " + this.convertToMonthYear(lastYearMonth)
        // provide no specific panel cost
        recommendationConfig.panel.actualCostPerPanel = null

        // initialize the preferences to the recomendations
        preferenceConfig.fullYearUsagekWh = totalYearUsage
        preferenceConfig.fullYearCost = totalYearCost
        preferenceConfig.fullYearCostAndUsagePeriod = this.convertToMonthYear(firstYearMonth) + " to " + this.convertToMonthYear(lastYearMonth)
        // provide no specific panel cost
        preferenceConfig.panel.actualCostPerPanel = null

        // update reactive variables 
        // all recommendation variables that are calcuated
        recommendationReactive.annualSavingsDuringLoan = recommendationConfig.annualSavingsDuringLoan();
        recommendationReactive.annualSavingsDuringLoan = recommendationConfig.annualSavingsDuringLoan();
        recommendationReactive.paybackPeriod = recommendationConfig.paybackPeriod();
        recommendationReactive.roiFiveYear = recommendationConfig.roiFiveYear();
        recommendationReactive.dailyPayment = recommendationConfig.dailyPayment();
        recommendationReactive.averageDailyEnergySpend = recommendationConfig.averageDailyEnergySpend();
        recommendationReactive.totalCost = recommendationConfig.totalCost();
        recommendationReactive.percentOfNeedMet = recommendationConfig.percentOfNeedMet();
        recommendationReactive.paybackPeriod = recommendationConfig.paybackPeriod();
        recommendationReactive.roiFiveYear = recommendationConfig.roiFiveYear();
        
        // recommendation parameters
        recommendationReactive.fullYearCost = recommendationConfig.fullYearCost;
        recommendationReactive.fullYearUsagekWh = totalYearUsage

        // things to update on charts - working ok, but not refreshing on charts when charts refresh
        panel.costPerDay = Array(12).fill(recommendationReactive.dailyPayment)
        // for highlighted area
        let allGridCosts = []
        chartData.years.forEach((year) => {
          let costsAsNumbers = chartData.costDailyHash[year].map(Number)
          allGridCosts = allGridCosts.concat(costsAsNumbers)
        })
        recommendationReactive.financeChartMaxDailyEnergySpend = Math.max(...allGridCosts)

        chartData.cashChartSeries = 
        [
          {
            name: "lifetime grid cost",
            data: [
              0,
              Math.round(recommendationReactive.fullYearCost * 3),
              Math.round(recommendationReactive.fullYearCost * 6),
              Math.round(recommendationReactive.fullYearCost * 9),
              Math.round(recommendationReactive.fullYearCost * 12),
            ],
          },
          {
            name: "lifetime panel cost",
            data: [
              Math.round(recommendationReactive.totalCost),
              Math.round(recommendationReactive.totalCost),
              Math.round(recommendationReactive.totalCost),
              Math.round(recommendationReactive.totalCost),
              Math.round(recommendationReactive.totalCost),
            ],
          },
        ];

        chartData.financeChartSeries = [
          {	      
            name: chartData.years[0], 
            type: 'column', 
            data: chartData.costDailyHash[chartData.years[0]],
          },
          {
            name: chartData.years[1],
            type: 'column',
            data: chartData.costDailyHash[chartData.years[1]],
          }, {
            name: chartData.years[2],
            type: 'column',
            data: chartData.costDailyHash[chartData.years[2]],
          }, {
            name: chartData.years[3],
            type: 'column',
            data: chartData.costDailyHash[chartData.years[3]],
          }, {
            name: 'Your estimated solar spend',
            type: 'line',
            data: panel.costPerDay,
          }
        ]
      };

      // manually refresh chart keys to refresh charts
      chartKeys.cash += 1;
      chartKeys.finance += 1;

      reader.onerror = (err) => console.log(err);
      reader.readAsText(this.file);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .center {
    display: flex;
    justify-content: center;
  }
</style>
