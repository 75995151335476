<script setup>
import { modalFlag } from '../store.js'
</script>

<template>
  <div class="modal-backdrop" v-if="modalFlag.isEversourceOnlineNotModalVisible">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          <div>
            You need to have an Eversource online account to get your data.  
          </div>

          <div>
            <a href="https://www.eversource.com/security/account/Register" target="_blank" rel="noopener">Sign up for Eversource online (free)</a>
          </div>
        </slot>
      </section>

      <footer class="modal-footer">
        <button type="button" class="btn-green" @click="close">
          Ok
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EversourceOnlineNotModal',
  methods: {
    close() {
      modalFlag.isEversourceOnlineNotModalVisible = false;
      modalFlag.isEversourceOnlineQuestionVisible = false;
      modalFlag.isEversourceCustomerQuestionVisible = false;
      this.$emit('close');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
