import { sunValues } from "../config.js";

export default class SolarConfig {
  constructor(
    sun,
    panel,
    fullYearUsagekWh,
    fullYearCost,
    consumerProductionPreference,
    consumerFinancingPreference,
    fullYearCostAndUsagePeriod,
    customerName,
    customerCityStateZip
  ) {
    this.sun = sun;
    this.panel = panel;
    this.fullYearUsagekWh = fullYearUsagekWh;
    this.fullYearCost = fullYearCost;
    this.consumerProductionPreference = consumerProductionPreference;
    this.consumerFinancingPreference = consumerFinancingPreference;
    this.fullYearCostAndUsagePeriod = fullYearCostAndUsagePeriod;
    this.customerName = customerName;
    this.customerCityStateZip = customerCityStateZip;
  }
  kwhProducedAnnuallyPerKw() {
    // see https://www.architecturaldigest.com/reviews/solar/how-many-solar-panels-do-i-need
    switch (this.sun) {
      case "Sunny":
        return sunValues.sunny;
      case "A little shade":
        return sunValues.littleShade;
      case "A lot of shade":
        return sunValues.lotOfShade;
    }
  }

  productionRatio() {
    return this.kwhProducedAnnuallyPerKw() / 1000;
  }

  numPanelsRequired() {
    if (this.fullYearUsagekWh) {
      const minimumPercentOfNeedMet =
        this.consumerProductionPreference.minimumPercentOfNeedMet / 100;
      const numPanelsRequired = Math.ceil(
        (this.fullYearUsagekWh * 1000 * minimumPercentOfNeedMet) /
          (this.panel.wattsPerPanel * this.kwhProducedAnnuallyPerKw())
      );
      return numPanelsRequired;
    } else {
      console.error("numPanelsRequired needs required full year usage");
      return null;
    }
  }

  spaceRequired() {
    return this.numPanelsRequired() * 17.5;
  }

  systemSizekW() {
    return (this.numPanelsRequired() * this.panel.wattsPerPanel) / 1000;
  }

  kwhProducedAnnually() {
    return this.systemSizekW() * this.kwhProducedAnnuallyPerKw();
  }

  percentOfNeedMet() {
    return this.kwhProducedAnnually() / this.fullYearUsagekWh;
  }

  calculatedCostPerPanel() {
    return this.panel.wattsPerPanel * 1.5;
  }

  costPerPanel() {
    if (this.panel.actualCostPerPanel == null) {
      // if no actual panel cost was provided
      return this.calculatedCostPerPanel();
    } else {
      // if actual panel cost was provided
      return this.panel.actualCostPerPanel;
    }
  }

  panelsCost() {
    return this.numPanelsRequired() * this.costPerPanel();
  }

  calculatedInstallationCost() {
    return this.panelsCost() * 0.25;
  }

  calculatedPanelsAndInstallationCost() {
    return this.panelsCost() + this.calculatedInstallationCost();
  }

  federalIncentive() {
    return -this.calculatedPanelsAndInstallationCost() * 0.3;
  }

  stateIncentives() {
    // MAIncomeTaxCredit
    return this.MAIncomeTaxIncentive() + this.MASmartIncentive();
  }

  MASmartIncentive() {
    return -0; // calculate this
  }

  MAIncomeTaxIncentive() {
    return -1000; // calculate this
  }

  // for financing

  periodicInterest() {
    return this.consumerFinancingPreference.interestRate / 100 / 12;
  }

  numPayments() {
    return this.consumerFinancingPreference.yearsOfLoan * 12;
  }

  totalCost() {
    return this.calculatedPanelsAndInstallationCost() + this.federalIncentive();
  }
  // financing methods
  numerator() {
    return this.totalCost() * this.periodicInterest();
  }

  denominator() {
    return 1 - (1 + this.periodicInterest()) ** (-1 * this.numPayments());
  }

  monthlyPayment() {
    const exactAmount = this.numerator() / this.denominator();
    return Math.round(exactAmount * 100) / 100;
  }

  dailyPayment() {
    const exactAmount = (this.monthlyPayment() * 12) / 365;
    return Math.round(exactAmount * 100) / 100;
  }

  annualPayments() {
    return this.monthlyPayment() * 12;
  }

  annualSavingsDuringLoan() {
    return this.fullYearCost - this.annualPayments();
  }

  averageDailyEnergySpend() {
    return this.fullYearCost / 365;
  }

  averageDailySavingsDuringLoan() {
    return this.annualSavingsDuringLoan() / 365;
  }

  // cash methods
  paybackPeriod() {
    return this.totalCost() / this.fullYearCost;
  }

  roiFiveYear() {
    return ((5 * this.fullYearCost) / this.totalCost()) * 100;
  }
}
