<script setup>
import { modalFlag, recommendationConfig, recommendationReactive, preferenceReactive } from '../store.js'
</script>

<template>
  <div>
    <h1>{{ msg }}</h1>
    <h2>A Smarter Way to Buy Solar Panels</h2>
    <FinanceMessage v-show='preferenceReactive.purchaseOption == "finance"' />
    <CashMessage v-show='preferenceReactive.purchaseOption == "cash"' />
    <h2>See what you could do!</h2>
    <TheChart/>

    <div class="grid-container">
        <!-- this button must go after the chart -->
        <button
        type="button"
        class="btn-green"
        @click="showReadMoreOnCarbon"
      >
        Read More on CO<sup>2</sup>
      </button>
      <ReadMoreOnCarbon
        v-show="modalFlag.isReadMoreOnCarbonVisible"
        @close="closeReadMoreOnCarbon"
        /> 
    </div>
    
    <div class="grid-container">
    <div class="grid-item">
     <h3>
       1. Get your data
       <br>
        <button
          type="button"
          class="btn-green"
          @click="showGetDataModal"
        >
          Eversource
        </button>
        <GetData
          v-show="modalFlag.isEversourceCustomerQuestionVisible"
          @close="closeGetDataModal"
          /> 
     </h3> 
        
    </div> 
    <div class="grid-item">
      <h3>
        2. Upload your data
        <ReadCSVButtonEversource/>
      </h3>
    </div>
    <div class="grid-item">
      <h3>
        3. Check the details
        <div>
          <img src="../assets/126472.png" width="30">
        </div>
      </h3>
    </div>
    <div class="grid-item">
      <h3>
        4. <a href="mailto:j.korenstein@gmail.com?subject=Jeff, I used the EnerChart web site.  Now I would like to shop for solar panels.">Buy Your Solar Panels</a>
        <div><img src="../assets/shopping-cart.png" width="30"></div>
        <div>Start saving money and the planet</div>
      </h3>
    </div>
  </div>
  <br>
  <div>
    <input type="radio" id="cash" value="cash" v-model="preferenceReactive.purchaseOption" >
    <!-- @change="showHitHouseVisible" -->
    <label for="cash">Cash</label>

    <input type="radio" id="finance" value="finance" v-model="preferenceReactive.purchaseOption">
    <label for="finance">Finance</label>

    <HitHouseToRefreshGraphScale v-show="modalFlag.isHitHouseVisible"/>
  </div> 
  <div v-show='preferenceReactive.purchaseOption == "finance"'>
    * Will save ${{ recommendationConfig.fullYearCost.toFixed(0) }} per year after {{ recommendationConfig.consumerFinancingPreference.yearsOfLoan }} years
    <br />
  </div>
    copyright <a href="mailto:j.korenstein@gmail.com">Jeff Korenstein</a> 2023
  </div>
</template>

<script>
import TheChart from '../components/TheChart.vue'
import GetData from './EversourceCustomerQuestion.vue';
import ReadMoreOnCarbon from './ReadMoreOnCarbon.vue'
import ReadCSVButtonEversource from './ReadCSVButtonEversource.vue'
import FinanceMessage from './FinanceMessage.vue'
import CashMessage from './CashMessage.vue';
import HitHouseToRefreshGraphScale from './HitHouseToRefreshGraphScale.vue';

export default {
  name: 'EnerChart',
  props: {
    msg: String
  },
  components: {
    TheChart,
    GetData,
    ReadMoreOnCarbon,
    ReadCSVButtonEversource,
    FinanceMessage,
    CashMessage,
    HitHouseToRefreshGraphScale
  },
  methods: {
    showGetDataModal() {
      modalFlag.isEversourceCustomerQuestionVisible = true;
    },
    closeGetDataModal() {
      modalFlag.isEversourceCustomerQuestionVisible = false;
    },
    showReadMoreOnCarbon() {
      modalFlag.isReadMoreOnCarbonVisible = true;
    },
    closeReadMoreOnCarbon() {
      modalFlag.isReadMoreOnCarbonVisible = false;
    },
    showHitHouseVisible() {
      modalFlag.isHitHouseVisible = true;
    },
    customerName() {
      if (recommendationReactive.customerName.startsWith("Sample:")) {
        return recommendationReactive.customerName.substring(8)
      } else {
        return recommendationReactive.customerName
      }
    }
  },
  computed: {
    wasPanelInvestmentDataModalClosed() {
      return !modalFlag.isPanelInvestmentDataVisible
    },
  }
}
</script>

<style>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  color: #42b983;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid#4AAE9B;
  border-radius: 2px; 
  margin: 10px 10px;
  font-size: 17px;
  padding: 10px;
}

/* from https://www.digitalocean.com/community/tutorials/vuejs-vue-modal-component */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
  padding: 10px;
  margin-left:10px
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 550px;
  background-color: #4AAE9B;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  top: -60px;
  right: 105%;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.grid-container {
  display: grid;

  @media 
  (max-width: 10000px){
    grid-template-columns: auto auto auto auto auto;
  }

  @media(max-width: 480px){
    grid-template-columns: auto;
  }

  justify-content: center;
  width: 90%;
  margin: 10 auto;
}
.grid-item {
  /* un-comment below to see gridlines */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 0px 5px;
}

</style>
